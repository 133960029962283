import { userRoles, userRolesDisplay } from "@models";
export const roleAlias = (
  roleId,
  program,
  plural = false,
  lowercase = false
) => {
  let name = userRolesDisplay[roleId];
  if (program) {
    const { program_roles } = program;
    const programRole = program_roles?.find(
      (r) => Number(r.role_id) === Number(roleId)
    );
    if (programRole?.alternative_role_name) {
      name = programRole.alternative_role_name;
    }
  }
  if (lowercase && name) {
    name = name.toLowerCase();
  }

  return plural ? `${name}s` : name;
};

export const isChampionFilterGroup = (groups) => {
  let isChampionGroup = false;
  groups.forEach((group) => {
    if (group.search_expr) {
      let searchExpr = JSON.parse(group.search_expr);
      isChampionGroup =
        isChampionGroup ||
        searchExpr?.filters
          .map((filter) => filter.expressions)
          .flat()
          .some(
            (expression) =>
              expression.field === "user_roles.role_id" &&
              expression.value === userRoles.CHAMPION
          );
    }
  });
  return isChampionGroup;
};

export const hasChampionOrProgramAdmin = (users) => {
  return (
    users.filter((user) => {
      return user.user_roles?.find(
        (userRole) =>
          [userRoles.CHAMPION, userRoles.PROGRAM_ADMIN].indexOf(
            userRole.role_id
          ) !== -1
      );
    }).length > 0
  );
};
export const isProgramExpired = (program) => {
  if (!program?.licence_start_date || !program?.licence_end_date) {
    return false;
  }
  const today = new Date();
  const licenceStartDate = new Date(program.licence_start_date);
  const licenceEndDate = new Date(program.licence_end_date);
  today.setHours(0, 0, 0, 0);
  licenceStartDate.setHours(0, 0, 0, 0);
  licenceEndDate.setHours(0, 0, 0, 0);
  return (
    licenceStartDate.getTime() > today.getTime() ||
    licenceEndDate.getTime() < today.getTime()
  );
};

export const getProgramExpiredTooltip = (program) => {
  return isProgramExpired(program)
    ? "No edits can be performed when a program has expired"
    : "";
};

export const MAX_INFORM_MENTOR_IN_LOCKED_MATCH = 1;

export const getMessageTranslation = (message, key, localeId) => {
  if (message.translations?.length) {
    const translation = message.translations.find(t => t.locale_id === localeId);
    if (translation) {
      return translation[key];
    }
  }
  return message[key];
};
export const isTrainingExpired = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return false;
  }
  const today = new Date();
  const availableStartDate = new Date(startDate);
  const availableEndDate = new Date(endDate);
  today.setHours(0, 0, 0, 0);
  availableStartDate.setHours(0, 0, 0, 0);
  availableEndDate.setHours(0, 0, 0, 0);
  return (
    availableStartDate.getTime() > today.getTime() ||
    availableEndDate.getTime() < today.getTime()
  );
};

export const isDisplaySegment = (segments, name) => {
  return segments.find(segment => segment.name === name);
};